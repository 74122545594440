import { default as _91id_939U7nAreEhYMeta } from "/vercel/path0/pages/ats/candidats/[id].vue?macro=true";
import { default as indexv3Tbft2a6EMeta } from "/vercel/path0/pages/ats/candidats/index.vue?macro=true";
import { default as candidatures_45spontaneeshtqfDx4V78Meta } from "/vercel/path0/pages/ats/candidatures-spontanees.vue?macro=true";
import { default as collaborateurs50QhNYpaOVMeta } from "/vercel/path0/pages/ats/employeur/collaborateurs.vue?macro=true";
import { default as emailIaXHSdeD8VMeta } from "/vercel/path0/pages/ats/employeur/email.vue?macro=true";
import { default as modelesKFDdq9LpKfMeta } from "/vercel/path0/pages/ats/employeur/modeles.vue?macro=true";
import { default as page_45emploi5nD8SxKNeDMeta } from "/vercel/path0/pages/ats/employeur/page-emploi.vue?macro=true";
import { default as indexGI2VqlXZIeMeta } from "/vercel/path0/pages/ats/index.vue?macro=true";
import { default as indexRtPCLYamA1Meta } from "/vercel/path0/pages/ats/offres/[id]/index.vue?macro=true";
import { default as modification4XkgpcMAhNMeta } from "/vercel/path0/pages/ats/offres/[id]/modification.vue?macro=true";
import { default as indexxQAvVcSG5aMeta } from "/vercel/path0/pages/ats/offres/index.vue?macro=true";
import { default as indexQntFY4BMcEMeta } from "/vercel/path0/pages/ats/statistiques/index.vue?macro=true";
import { default as indexLoM8BeFJ65Meta } from "/vercel/path0/pages/career/[slug]/index.vue?macro=true";
import { default as _91job_93IzLOehWAhVMeta } from "/vercel/path0/pages/career/[slug]/jobs/[job].vue?macro=true";
import { default as indexOmFKZXSFUSMeta } from "/vercel/path0/pages/career/[slug]/jobs/index.vue?macro=true";
import { default as conditions_45generalesShNoDLnComMeta } from "/vercel/path0/pages/conditions-generales.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91slug_93KMd6cPdYkvMeta } from "/vercel/path0/pages/employeurs/[slug].vue?macro=true";
import { default as indexMBjjnLUZjDMeta } from "/vercel/path0/pages/employeurs/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexEk4dtrDptRMeta } from "/vercel/path0/pages/jobs/[slug]/index.vue?macro=true";
import { default as vignettemGfW2C4O2BMeta } from "/vercel/path0/pages/jobs/[slug]/vignette.vue?macro=true";
import { default as index4iqCgT2ECDMeta } from "/vercel/path0/pages/jobs/index.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as alertes_45emploi2WeIqWCnLIMeta } from "/vercel/path0/pages/mon-compte/alertes-emploi.vue?macro=true";
import { default as candidaturesiaxDXhzy8PMeta } from "/vercel/path0/pages/mon-compte/candidatures.vue?macro=true";
import { default as favorisQU84s9Js9aMeta } from "/vercel/path0/pages/mon-compte/favoris.vue?macro=true";
import { default as indexvnQrguQxAUMeta } from "/vercel/path0/pages/mon-compte/index.vue?macro=true";
import { default as nos_45partenairesJWc0c6htMKMeta } from "/vercel/path0/pages/nos-partenaires.vue?macro=true";
import { default as nos_45services1Hf5eHhwHuMeta } from "/vercel/path0/pages/nos-services.vue?macro=true";
import { default as politique_45de_45donneest4emK3bLgcMeta } from "/vercel/path0/pages/politique-de-donnees.vue?macro=true";
import { default as presentation_45atsQUoZQnDpxBMeta } from "/vercel/path0/pages/presentation-ats.vue?macro=true";
export default [
  {
    name: _91id_939U7nAreEhYMeta?.name ?? "ats-candidats-id",
    path: _91id_939U7nAreEhYMeta?.path ?? "/ats/candidats/:id()",
    meta: _91id_939U7nAreEhYMeta || {},
    alias: _91id_939U7nAreEhYMeta?.alias || [],
    redirect: _91id_939U7nAreEhYMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/candidats/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv3Tbft2a6EMeta?.name ?? "ats-candidats",
    path: indexv3Tbft2a6EMeta?.path ?? "/ats/candidats",
    meta: indexv3Tbft2a6EMeta || {},
    alias: indexv3Tbft2a6EMeta?.alias || [],
    redirect: indexv3Tbft2a6EMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/candidats/index.vue").then(m => m.default || m)
  },
  {
    name: candidatures_45spontaneeshtqfDx4V78Meta?.name ?? "ats-candidatures-spontanees",
    path: candidatures_45spontaneeshtqfDx4V78Meta?.path ?? "/ats/candidatures-spontanees",
    meta: candidatures_45spontaneeshtqfDx4V78Meta || {},
    alias: candidatures_45spontaneeshtqfDx4V78Meta?.alias || [],
    redirect: candidatures_45spontaneeshtqfDx4V78Meta?.redirect,
    component: () => import("/vercel/path0/pages/ats/candidatures-spontanees.vue").then(m => m.default || m)
  },
  {
    name: collaborateurs50QhNYpaOVMeta?.name ?? "ats-employeur-collaborateurs",
    path: collaborateurs50QhNYpaOVMeta?.path ?? "/ats/employeur/collaborateurs",
    meta: collaborateurs50QhNYpaOVMeta || {},
    alias: collaborateurs50QhNYpaOVMeta?.alias || [],
    redirect: collaborateurs50QhNYpaOVMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/employeur/collaborateurs.vue").then(m => m.default || m)
  },
  {
    name: emailIaXHSdeD8VMeta?.name ?? "ats-employeur-email",
    path: emailIaXHSdeD8VMeta?.path ?? "/ats/employeur/email",
    meta: emailIaXHSdeD8VMeta || {},
    alias: emailIaXHSdeD8VMeta?.alias || [],
    redirect: emailIaXHSdeD8VMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/employeur/email.vue").then(m => m.default || m)
  },
  {
    name: modelesKFDdq9LpKfMeta?.name ?? "ats-employeur-modeles",
    path: modelesKFDdq9LpKfMeta?.path ?? "/ats/employeur/modeles",
    meta: modelesKFDdq9LpKfMeta || {},
    alias: modelesKFDdq9LpKfMeta?.alias || [],
    redirect: modelesKFDdq9LpKfMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/employeur/modeles.vue").then(m => m.default || m)
  },
  {
    name: page_45emploi5nD8SxKNeDMeta?.name ?? "ats-employeur-page-emploi",
    path: page_45emploi5nD8SxKNeDMeta?.path ?? "/ats/employeur/page-emploi",
    meta: page_45emploi5nD8SxKNeDMeta || {},
    alias: page_45emploi5nD8SxKNeDMeta?.alias || [],
    redirect: page_45emploi5nD8SxKNeDMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/employeur/page-emploi.vue").then(m => m.default || m)
  },
  {
    name: indexGI2VqlXZIeMeta?.name ?? "ats",
    path: indexGI2VqlXZIeMeta?.path ?? "/ats",
    meta: indexGI2VqlXZIeMeta || {},
    alias: indexGI2VqlXZIeMeta?.alias || [],
    redirect: indexGI2VqlXZIeMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/index.vue").then(m => m.default || m)
  },
  {
    name: indexRtPCLYamA1Meta?.name ?? "ats-offres-id",
    path: indexRtPCLYamA1Meta?.path ?? "/ats/offres/:id()",
    meta: indexRtPCLYamA1Meta || {},
    alias: indexRtPCLYamA1Meta?.alias || [],
    redirect: indexRtPCLYamA1Meta?.redirect,
    component: () => import("/vercel/path0/pages/ats/offres/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: modification4XkgpcMAhNMeta?.name ?? "ats-offres-id-modification",
    path: modification4XkgpcMAhNMeta?.path ?? "/ats/offres/:id()/modification",
    meta: modification4XkgpcMAhNMeta || {},
    alias: modification4XkgpcMAhNMeta?.alias || [],
    redirect: modification4XkgpcMAhNMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/offres/[id]/modification.vue").then(m => m.default || m)
  },
  {
    name: indexxQAvVcSG5aMeta?.name ?? "ats-offres",
    path: indexxQAvVcSG5aMeta?.path ?? "/ats/offres",
    meta: indexxQAvVcSG5aMeta || {},
    alias: indexxQAvVcSG5aMeta?.alias || [],
    redirect: indexxQAvVcSG5aMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/offres/index.vue").then(m => m.default || m)
  },
  {
    name: indexQntFY4BMcEMeta?.name ?? "ats-statistiques",
    path: indexQntFY4BMcEMeta?.path ?? "/ats/statistiques",
    meta: indexQntFY4BMcEMeta || {},
    alias: indexQntFY4BMcEMeta?.alias || [],
    redirect: indexQntFY4BMcEMeta?.redirect,
    component: () => import("/vercel/path0/pages/ats/statistiques/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoM8BeFJ65Meta?.name ?? "career-slug",
    path: indexLoM8BeFJ65Meta?.path ?? "/career/:slug()",
    meta: indexLoM8BeFJ65Meta || {},
    alias: indexLoM8BeFJ65Meta?.alias || [],
    redirect: indexLoM8BeFJ65Meta?.redirect,
    component: () => import("/vercel/path0/pages/career/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91job_93IzLOehWAhVMeta?.name ?? "career-slug-jobs-job",
    path: _91job_93IzLOehWAhVMeta?.path ?? "/career/:slug()/jobs/:job()",
    meta: _91job_93IzLOehWAhVMeta || {},
    alias: _91job_93IzLOehWAhVMeta?.alias || [],
    redirect: _91job_93IzLOehWAhVMeta?.redirect,
    component: () => import("/vercel/path0/pages/career/[slug]/jobs/[job].vue").then(m => m.default || m)
  },
  {
    name: indexOmFKZXSFUSMeta?.name ?? "career-slug-jobs",
    path: indexOmFKZXSFUSMeta?.path ?? "/career/:slug()/jobs",
    meta: indexOmFKZXSFUSMeta || {},
    alias: indexOmFKZXSFUSMeta?.alias || [],
    redirect: indexOmFKZXSFUSMeta?.redirect,
    component: () => import("/vercel/path0/pages/career/[slug]/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: conditions_45generalesShNoDLnComMeta?.name ?? "conditions-generales",
    path: conditions_45generalesShNoDLnComMeta?.path ?? "/conditions-generales",
    meta: conditions_45generalesShNoDLnComMeta || {},
    alias: conditions_45generalesShNoDLnComMeta?.alias || [],
    redirect: conditions_45generalesShNoDLnComMeta?.redirect,
    component: () => import("/vercel/path0/pages/conditions-generales.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KMd6cPdYkvMeta?.name ?? "employeurs-slug",
    path: _91slug_93KMd6cPdYkvMeta?.path ?? "/employeurs/:slug()",
    meta: _91slug_93KMd6cPdYkvMeta || {},
    alias: _91slug_93KMd6cPdYkvMeta?.alias || [],
    redirect: _91slug_93KMd6cPdYkvMeta?.redirect,
    component: () => import("/vercel/path0/pages/employeurs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMBjjnLUZjDMeta?.name ?? "employeurs",
    path: indexMBjjnLUZjDMeta?.path ?? "/employeurs",
    meta: indexMBjjnLUZjDMeta || {},
    alias: indexMBjjnLUZjDMeta?.alias || [],
    redirect: indexMBjjnLUZjDMeta?.redirect,
    component: () => import("/vercel/path0/pages/employeurs/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEk4dtrDptRMeta?.name ?? "jobs-slug",
    path: indexEk4dtrDptRMeta?.path ?? "/jobs/:slug()",
    meta: indexEk4dtrDptRMeta || {},
    alias: indexEk4dtrDptRMeta?.alias || [],
    redirect: indexEk4dtrDptRMeta?.redirect,
    component: () => import("/vercel/path0/pages/jobs/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: vignettemGfW2C4O2BMeta?.name ?? "jobs-slug-vignette",
    path: vignettemGfW2C4O2BMeta?.path ?? "/jobs/:slug()/vignette",
    meta: vignettemGfW2C4O2BMeta || {},
    alias: vignettemGfW2C4O2BMeta?.alias || [],
    redirect: vignettemGfW2C4O2BMeta?.redirect,
    component: () => import("/vercel/path0/pages/jobs/[slug]/vignette.vue").then(m => m.default || m)
  },
  {
    name: index4iqCgT2ECDMeta?.name ?? "jobs",
    path: index4iqCgT2ECDMeta?.path ?? "/jobs",
    meta: index4iqCgT2ECDMeta || {},
    alias: index4iqCgT2ECDMeta?.alias || [],
    redirect: index4iqCgT2ECDMeta?.redirect,
    component: () => import("/vercel/path0/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceLjk4bqrWMWMeta?.name ?? "maintenance",
    path: maintenanceLjk4bqrWMWMeta?.path ?? "/maintenance",
    meta: maintenanceLjk4bqrWMWMeta || {},
    alias: maintenanceLjk4bqrWMWMeta?.alias || [],
    redirect: maintenanceLjk4bqrWMWMeta?.redirect,
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesaI7UrXH82KMeta?.name ?? "mentions-legales",
    path: mentions_45legalesaI7UrXH82KMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesaI7UrXH82KMeta || {},
    alias: mentions_45legalesaI7UrXH82KMeta?.alias || [],
    redirect: mentions_45legalesaI7UrXH82KMeta?.redirect,
    component: () => import("/vercel/path0/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: alertes_45emploi2WeIqWCnLIMeta?.name ?? "mon-compte-alertes-emploi",
    path: alertes_45emploi2WeIqWCnLIMeta?.path ?? "/mon-compte/alertes-emploi",
    meta: alertes_45emploi2WeIqWCnLIMeta || {},
    alias: alertes_45emploi2WeIqWCnLIMeta?.alias || [],
    redirect: alertes_45emploi2WeIqWCnLIMeta?.redirect,
    component: () => import("/vercel/path0/pages/mon-compte/alertes-emploi.vue").then(m => m.default || m)
  },
  {
    name: candidaturesiaxDXhzy8PMeta?.name ?? "mon-compte-candidatures",
    path: candidaturesiaxDXhzy8PMeta?.path ?? "/mon-compte/candidatures",
    meta: candidaturesiaxDXhzy8PMeta || {},
    alias: candidaturesiaxDXhzy8PMeta?.alias || [],
    redirect: candidaturesiaxDXhzy8PMeta?.redirect,
    component: () => import("/vercel/path0/pages/mon-compte/candidatures.vue").then(m => m.default || m)
  },
  {
    name: favorisQU84s9Js9aMeta?.name ?? "mon-compte-favoris",
    path: favorisQU84s9Js9aMeta?.path ?? "/mon-compte/favoris",
    meta: favorisQU84s9Js9aMeta || {},
    alias: favorisQU84s9Js9aMeta?.alias || [],
    redirect: favorisQU84s9Js9aMeta?.redirect,
    component: () => import("/vercel/path0/pages/mon-compte/favoris.vue").then(m => m.default || m)
  },
  {
    name: indexvnQrguQxAUMeta?.name ?? "mon-compte",
    path: indexvnQrguQxAUMeta?.path ?? "/mon-compte",
    meta: indexvnQrguQxAUMeta || {},
    alias: indexvnQrguQxAUMeta?.alias || [],
    redirect: indexvnQrguQxAUMeta?.redirect,
    component: () => import("/vercel/path0/pages/mon-compte/index.vue").then(m => m.default || m)
  },
  {
    name: nos_45partenairesJWc0c6htMKMeta?.name ?? "nos-partenaires",
    path: nos_45partenairesJWc0c6htMKMeta?.path ?? "/nos-partenaires",
    meta: nos_45partenairesJWc0c6htMKMeta || {},
    alias: nos_45partenairesJWc0c6htMKMeta?.alias || [],
    redirect: nos_45partenairesJWc0c6htMKMeta?.redirect,
    component: () => import("/vercel/path0/pages/nos-partenaires.vue").then(m => m.default || m)
  },
  {
    name: nos_45services1Hf5eHhwHuMeta?.name ?? "nos-services",
    path: nos_45services1Hf5eHhwHuMeta?.path ?? "/nos-services",
    meta: nos_45services1Hf5eHhwHuMeta || {},
    alias: nos_45services1Hf5eHhwHuMeta?.alias || [],
    redirect: nos_45services1Hf5eHhwHuMeta?.redirect,
    component: () => import("/vercel/path0/pages/nos-services.vue").then(m => m.default || m)
  },
  {
    name: politique_45de_45donneest4emK3bLgcMeta?.name ?? "politique-de-donnees",
    path: politique_45de_45donneest4emK3bLgcMeta?.path ?? "/politique-de-donnees",
    meta: politique_45de_45donneest4emK3bLgcMeta || {},
    alias: politique_45de_45donneest4emK3bLgcMeta?.alias || [],
    redirect: politique_45de_45donneest4emK3bLgcMeta?.redirect,
    component: () => import("/vercel/path0/pages/politique-de-donnees.vue").then(m => m.default || m)
  },
  {
    name: presentation_45atsQUoZQnDpxBMeta?.name ?? "presentation-ats",
    path: presentation_45atsQUoZQnDpxBMeta?.path ?? "/presentation-ats",
    meta: presentation_45atsQUoZQnDpxBMeta || {},
    alias: presentation_45atsQUoZQnDpxBMeta?.alias || [],
    redirect: presentation_45atsQUoZQnDpxBMeta?.redirect,
    component: () => import("/vercel/path0/pages/presentation-ats.vue").then(m => m.default || m)
  }
]